import { IData } from "../components/Form";
import { getPaymentResult, hash, IPaymentData } from "./api";
import { cloudPayments } from "./cloudPayments";
import { yandexEvent } from "./metrics";
import { getIokaLink } from "./payments";

export default async function submit (
  formData: IData,
  setPayedStatus: (status: "success" | "failed") => void,
  setHref: (operation: string) => void, token: string,
  partner: string
) {
    const resultsUrl = `${process.env.REACT_APP_SUN}/result#`
    const { name, email, time, promo, date, country } = formData;
    const { name: place, lat, lon } = formData.place!;

    try {
      const dataPost: IPaymentData = {
        email,
        partner,
        promo,
        name,
        date: date!,
        time: time!,
        place,
        lat,
        lon,
        token,
        subtype: 'sia',
        url: resultsUrl,
        country,
        backUrl: getIokaLink('back'),
        failUrl: getIokaLink('fail'),
        successUrl: getIokaLink('success')
      };

      dataPost.operation = await hash(JSON.stringify(dataPost));
      dataPost.url = dataPost.url + dataPost.operation
      setHref(dataPost.operation)
      const result: any = await getPaymentResult(dataPost)

      const success = () => {
        yandexEvent('widget-payment-success');
        setPayedStatus('success');
      };

      if(result?.ioka?.success && result?.ioka?.url) {
        window.location.href = result.ioka.url;
        return;
      }
      if (result.promo && result.success) {
        success();
        return;
      }

      if (result.failed && result.data.length > 0) {
        const error = result.data[0].trim();
        switch (error) {
          case 'PROMO_ALREADY_USED':
            alert('Промокод уже был использован');
            return;
          case 'PROMO_EXPIRED':
            alert('Срок действия промокода истек');
            return;
          case 'PROMO_NOT_FOUND':
            alert('Промокод не найден');
            return;
        }
        throw 'Config error';
      }

      const CustomerReceipt = { ...result.receipt, email };

      const data: IPaymentData = {
        type: 'widget',
        partner: partner,
        cloudPayments: { CustomerReceipt },
        place,
        lat,
        lon,
        name,
        email,
        date: date!,
        time: time!,
        promo,
        subtype: 'sia',
        createdAt: Date.now(),
        url: resultsUrl,
        K8S_NAMESPACE: result.K8S_NAMESPACE,
      };

      data.operation = await hash(JSON.stringify(data));
      data.url = data.url + data.operation
      setHref(data.operation)
      Object.assign(result.options, {
        InvoiceId: token,
        data,
        email,
        requireEmail: true,
        skin: 'mini',
      });

      const cancel = () => {
        yandexEvent('widget-payment-cancel');
        setPayedStatus('failed');
      };

      const cp = async () => {
        try {
          const cp = await cloudPayments();
          new cp().charge(result.options, success, cancel);
        } catch (error) {
          yandexEvent('widget-payment-fail');
        }
      };
      cp();
      yandexEvent('click-postroit-ipr');
    } catch (error) {
      alert('Сервис временно недоступен!');
      console.log(error);
    }

}
