export  async function cloudPayments() {
  await new Promise((resolve, reject) => {
    const script = document.createElement('script');
    document.head.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
    script.async = true;
    script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments';
  });
  //@ts-ignore
  return window.cp.CloudPayments;
}