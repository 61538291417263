import React, {useState} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
// import { mainApi } from '../helpers/api';
import { fromDateTime, IPlace, PaymentCardPicker, DateTimeInput, InputContainer, Input, Button, PlaceInput} from '../libs'
// import { IPlan } from './Plan';
import * as EmailValidator from 'email-validator';
import Promo from './Promo';
import {mainApi, s3urls, IPaymentCardMethod} from "../helpers/api";

dayjs.extend(utcPlugin);

const paymentMethods: IPaymentCardMethod[] = [{
  country: 'ru',
  title: 'Российская карта',
}, {
  country: '*',
  title: 'Иностранная карта',
}]

// FIXME: duplicate
export interface IData {
  place: {
    name: string;
    lat: number;
    lon: number;
  } | null;
  name: string;
  email: string;
  date: string | null;
  time: string | null;
  promo?: string;
  autoprediction?: boolean;
  country: string;
}

export default function Form({
  onSubmit,
  price = null,
  token
}: {
  onSubmit(data: IData, token: string): void;
  price: number | null;
  token: string;
}) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [time, setTime] = useState<string>('00:00:00');
  const [date, setDate] = useState<string | null>('');
  const [place, setPlace] = useState<IPlace | null>(null);

  const [nameMessage, setNameMessage] = useState<string | undefined>();
  const [emailMessage, setEmailMessage] = useState<string | undefined>();
  const [timeMessage, setTimeMessage] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [placeMessage, setPlaceMessage] = useState<string | undefined>();

  const [promo, setPromo] = useState('');
  // const [autoprediction, setAutoprediction] = useState<boolean>(false)

  const [paymentMethod, setPaymentMethod] = React.useState(paymentMethods[0])

  const isDisabled = React.useMemo((): boolean => {
    return Boolean(
      nameMessage || !name ||
      emailMessage || !email ||
      timeMessage || !time ||
      dateMessage || !date ||
      placeMessage || !place
    );
  }, [nameMessage, name, emailMessage, email, timeMessage, time, dateMessage, date, placeMessage, place]);

  const onNameChange = (name: string) => {
    const resultName = name.trim();
    setName(name);
    if (/^[a-z,а-я,ё,\s]*$/i.test(resultName)) {
      setNameMessage(resultName.length === 0 ? 'Имя не может быть пустым' : undefined);
    } else {
      setNameMessage('В имени могут быть только буквы');
    }
  }

  const onEmailChange = (email: string) => {
    setEmail(email);
    setEmailMessage(EmailValidator.validate(email) || email === '' ? undefined : 'Введите корректный email');
  }

  const changePromo = (promo: string) => {
    setPromo(promo);
  }

  const onDateChange = (isoDate?: string) => {
    const message = 'Нужно ввести дату'
    if (isoDate) {
      const [year, month, day] = isoDate.split('T')[0].split('-');
      const date = `${day}.${month}.${year}`;
      const yearNumber = Number(year);
      setDate(date);
      setDateMessage(yearNumber < 1900 ? 'Введите корректную дату' : yearNumber > dayjs().year() ? 'Введите дату в прошлом' : undefined);
    } else {
      setDateMessage(message);
    }
  }

  const onTimeChange = (isoTime?: string) => {
    const message = 'Нужно ввести время'
    if (isoTime) {
      const time = dayjs(isoTime).utc().format('HH:mm:ss');
      setTime(time);
      setTimeMessage(undefined);
    } else {
      setTimeMessage(message);
    }
  }

  const onPlaceChange = (place: IPlace | string | null) => {
    if (typeof place === 'object') {
      setPlace(place);
      setPlaceMessage(undefined);
    } else {
      setPlace(place => ({ ...place!, lat: 0, lon: 0 }));
      setPlaceMessage('Выберите город из списка');
    }
  }

  const checkErrorMode = (val: any) => Boolean(val) ? 'error' : 'normal';

  const getLinks = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
      case '*':
				return (
          <a href={s3urls.termsOfUseKZ} target='_blank' rel='noreferrer'>
            Пользовательское соглашение
          </a>
				)
			default:
				return (
					<a href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>
            Пользовательское соглашение
          </a>
				)
		}
	}

  return (
    <Container>
      <Title>Сделайте первый шаг - заполните форму</Title>
      <SubTitle>Узнайте, как получить дополнительную энергию уже сегодня</SubTitle>
      <ContainerForm>

        <StyledInputContainer label='Имя' message={nameMessage}>
          <StyledInput
            size='small'
            value={name}
            onChange={onNameChange}
            placeholder='Как вас зовут?'
            mode={checkErrorMode(nameMessage)}
          />
        </StyledInputContainer>

        <StyledInputContainer label='Почта' message={emailMessage}>
          <StyledInput
            size='small'
            value={email}
            onChange={onEmailChange}
            placeholder='Ваш e-mail'
            mode={checkErrorMode(emailMessage)}
          />
        </StyledInputContainer>

        <StyledInputContainer label='Дата рождения' message={dateMessage}>
          <StyledDateTimeInput
            size='small'
            type='date'
            value={''}
            onChange={onDateChange}
            onInvalid={onDateChange}
            hideIcon={true}
            inputmode='numeric'
            mode={checkErrorMode(dateMessage)}
          />
        </StyledInputContainer>

        <StyledInputContainer label='Время рождения' message={timeMessage}>
          <StyledDateTimeInput
            size='small'
            type='time'
            value={fromDateTime('01.02.1989', time)}
            inputmode='numeric'
            hideIcon={true}
            onChange={onTimeChange}
            onInvalid={onTimeChange}
            mode={checkErrorMode(timeMessage)}
          />
        </StyledInputContainer>

        <StyledInputContainer label='Место рождения' message={placeMessage}>
          <StyledPlaceInput
            size='small'
            value={place?.name || ''}
            onSelect={onPlaceChange}
            onChange={onPlaceChange}
            placeholder='Начните вводить место рождения'
            asyncDataFn={mainApi.places.bind(mainApi)}
            asyncDetailFn={mainApi.place.bind(mainApi)}
            mode={checkErrorMode(placeMessage)}
            lang="ru"
          />
        </StyledInputContainer>

        <Promo
          promo={promo}
          setPromo={changePromo}
        />

        <PaymentCardPicker items={paymentMethods} value={paymentMethod} onChange={setPaymentMethod} showInfo={false} />
        
        <Button
          style={{height: '3rem'}}
          color="accent"
          onClick={() => onSubmit?.({
            place,
            name: name.trim(),
            email: email.trim(),
            date,
            time,
            promo,
            country: paymentMethod.country,
          }, token)}
          disabled={isDisabled}
        >
          Получить инструкцию за <Price>{price}</Price> ₽
        </Button>

        <Footer>
          Нажимая «Получить инструкцию», вы принимаете <br/>
          {getLinks()}
        </Footer>
      </ContainerForm></Container>
  );
}

const Container = styled.div`
height: 100%;
  width: 50%;
  max-width: 90%;
  margin: auto;

  @media (max-width: 768px) {
    width: 80%;
    height: 100%;
  }
`

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  font-size: 0.875rem;

  & > :nth-child(n + 5) {
    grid-column: 1 / 3;
  }

  & > button {
    margin-top: 0.7rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--white-color);
    font-size: 1rem;
    background: var(--main-color);

    &[disabled] {
      color: var(--main-color);
      background: var(--main-light-color);
    }
  }

  @media (max-width: 1125px) {
    & > :nth-child(1) {
      grid-column: 1 / 3;
    }

    & > :nth-child(2) {
      grid-column: 1 / 3;
    }
  }
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  text-align: left;
`

const SubTitle = styled.div`
  color: var(--gray-color);
  text-align: left;
  margin-bottom: 2rem;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const StyledInputContainer = styled(InputContainer)`
  height: 5.6rem;
`

const StyledInput = styled(Input)`
  &:focus {
    border: 1px solid var(--main-color);
  }
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const StyledDateTimeInput = styled(DateTimeInput)`
  &:focus {
    border: 1px solid var(--main-color);
  }
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const StyledPlaceInput = styled(PlaceInput)`
  &:focus {
    border: 1px solid var(--main-color);
  }


  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const Price = styled.span`
  font-size: 1.1rem;
`;
