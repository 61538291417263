import React from 'react';
import { getStartConfigPrices, IStartConfigPrices } from '../../helpers/api';

export const StartConfigContext = React.createContext<IStartConfigPrices | null>(null);

export function StartConfigProvider({
  children,
  token,
  partner = '',
}: {
  children: React.ReactNode
  token: string
  partner: string
}) {
  const [prices, setPrices] = React.useState<IStartConfigPrices | null>(null);

  React.useEffect(() => {
    getStartConfigPrices(token, partner).then(prices => {
      const computedPrices: IStartConfigPrices = {
        default: prices.default,
      };
      setPrices(computedPrices);
    })
  }, [])

  const IStartConfigContextValue: IStartConfigPrices | null = prices
  
  return <StartConfigContext.Provider value={IStartConfigContextValue}>
    {children}
  </StartConfigContext.Provider>
}
